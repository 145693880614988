import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';

const base = 'https://res.cloudinary.com/thefeedcom/image/fetch/e_trim:0,w_1200,h_1200'
/*
https://res.cloudinary.com/thefeedcom/image/fetch/e_trim,w_1200,h_1200/https://cdn.shopify.com/s/files/1/1515/2714/products/amp-human-supplements-10-6oz-amp-human-next-generation-pr-lotion-28028835889215.jpg
*/

function App() {
  
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  
  let shopifyUrls = [
    'https://cdn.shopify.com/s/files/1/1515/2714/products/amp-human-supplements-10-6oz-amp-human-next-generation-pr-lotion-28028835889215.jpg',
    'https://cdn.shopify.com/s/files/1/1515/2714/products/Gu_Rasp_Lem_Opt_2.png',
    'https://cdn.shopify.com/s/files/1/1515/2714/products/the-feed-set-of-am-pm-90-capsules-each-thorne-multi-vitamin-elite-5428882767935.jpg',
    'https://cdn.shopify.com/s/files/1/1515/2714/products/amp-human-supplements-10-6oz-amp-human-next-generation-pr-lotion-28028835889215.jpg',
    'https://cdn.shopify.com/s/files/1/1515/2714/products/Gu_Rasp_Lem_Opt_2.png',
    'https://cdn.shopify.com/s/files/1/1515/2714/products/the-feed-set-of-am-pm-90-capsules-each-thorne-multi-vitamin-elite-5428882767935.jpg',
  ]

  const urlImages = urlParams.get('images')
  if(urlImages) {
    shopifyUrls = []
    urlImages.split(',').forEach((url) => {
      shopifyUrls.push(url)
    })
  }

  const [images, setImages] = useState(null)

  useEffect(() => {
    
    const getMeta = async (url) => {
      const tmp = []
      for(const url of shopifyUrls) {
        const json = await fetch(`${base}/fl_getinfo/${url}`).then(res => res.json())
        tmp.push({
          url,
          meta: json,
        })
      }
      setImages(tmp)
    }
  
    getMeta()
  
  }, [])
  
  const checkForFit = ({ rowHeight, imagesArray, containerWidth, containerHeight }) => {

    let fit = true
    let x = 0
    let y = 0

    //add margin
    // const MARGIN = 10 * 2
    const MARGIN = 10 + 1 //1 extra for rounding 
    // rowHeight = rowHeight

    imagesArray.forEach((image, index) => {

      const width = image.meta.output.width
      const height = image.meta.output.height
      
      const ratio = width / height
      // let scaledWidth = rowHeight * ratio

      //this is the x,y space that this image occupied (after rotation)
      let xSpace = Math.floor(rowHeight * ratio)
      let ySpace = rowHeight

      //if it's a landscape image then rotate it
      if(ratio > 1) {
        image.rotate = true
        
        image.displayWidth = rowHeight
        image.displayHeight = Math.floor(rowHeight / ratio)

        xSpace = Math.floor(rowHeight / ratio)

      } else {
        image.displayWidth = xSpace
        image.displayHeight = ySpace
      }


      if(x + xSpace + (MARGIN * 2) > containerWidth) {
        //this would exceed the width, wrap it
        image.wrapped = true
        x = xSpace + (MARGIN * 2)
        y = y + rowHeight + (MARGIN * 2)
      } else {
        image.wrapped = false
        x = x + xSpace + (MARGIN * 2)
      }

      image.xSpace = xSpace
      image.ySpace = ySpace

      if(y + rowHeight + (MARGIN * 2) >= containerHeight) {
        //too tall
        fit = false
      }
    })

    return fit
  }

  const calculateMaxRowHeight = (imagesArray) => {
    
    const containerWidth = 940
    const containerHeight = 940
    
    let rowHeight = 10
    let contained = true

    while(rowHeight <= containerHeight && contained === true) {

      const fit = checkForFit({
        rowHeight,
        imagesArray,
        containerWidth,
        containerHeight,
      })
      console.log(rowHeight, fit)
      
      // if(rowHeight === 374) {
      //   debugger
      // }

      if(fit === false) {
        contained = false
      } else {
        rowHeight++
      }
    }

    return rowHeight

  }

  if(!images) return null

  calculateMaxRowHeight(images)

  // const fit = checkForFit({
  //   rowHeight: 340,
  //   imagesArray: images,
  //   containerWidth: 750,
  //   containerHeight: 750,
  // })
  // debugger

  return (
    <div className="App">
      <div className="container">
        {images.map((image, index) => {

          const ratio = image.displayWidth / image.displayHeight
          if(ratio > 1) {
            return (
              <div style={{ width: `${Math.floor(image.displayHeight)}px`, height: `${Math.floor(image.displayWidth)}px`, left: `${image.displayHeight}px` }}>
                {/* <span>{index}: x{Math.round(image.xSpace)},y{Math.round(image.ySpace)}</span> */}
                <img src={`${base}/${image.url}`} width={image.displayWidth} height={image.displayHeight} key={`image${index}`} className={`${image.rotate ? 'rotate' : ''} ${image.wrapped ? 'wrapped' : ''}`} />
              </div>
            )
          } else {
            return (
              <div style={{ width: `${Math.floor(image.displayWidth)}px`, height: `${Math.floor(image.displayHeight)}px` }}>
                {/* <span>{index}: x{Math.round(image.xSpace)},y{Math.round(image.ySpace)}</span> */}
                <img src={`${base}/${image.url}`} width={image.displayWidth} height={image.displayHeight} key={`image${index}`} className={`${image.rotate ? 'rotate' : ''} ${image.wrapped ? 'wrapped' : ''}`} />
              </div>
            )
          }

        })}
      </div>
    </div>
  );
}

export default App;
