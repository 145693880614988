// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  background: gray;
}

.container {
  position: relative;
  width: 940px;
  height: 940px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: white;
  border: 30px solid white;
}

.container img {
  /* margin: 20px; */
}

.container span {
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  z-index: 2;
}

.container img.wrapped {
  /* box-shadow: 0 0 0 1px red; */
}

.container img.rotate {
  transform: rotate(90deg);
  transform-origin: 0 0;
}

.container div {
  padding: 10px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB","sourcesContent":["body {\n  margin: 0;\n  background: gray;\n}\n\n.container {\n  position: relative;\n  width: 940px;\n  height: 940px;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n  background: white;\n  border: 30px solid white;\n}\n\n.container img {\n  /* margin: 20px; */\n}\n\n.container span {\n  position: absolute;\n  left: 0;\n  top: 0;\n  background: white;\n  z-index: 2;\n}\n\n.container img.wrapped {\n  /* box-shadow: 0 0 0 1px red; */\n}\n\n.container img.rotate {\n  transform: rotate(90deg);\n  transform-origin: 0 0;\n}\n\n.container div {\n  padding: 10px;\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
